const isProd = process.env.NODE_ENV !== 'development'

export default {
    apiHost:
        process.env.VUE_APP_API_HOST || (
            isProd ? 'https://api.lesreparables.fr/api' : 'http://localhost:8082/api'
        ),
    basePath: process.env.VUE_APP_BASE_PATH || (
        isProd ? 'https://lesreparables.fr/je-repare-mes-vetements' : 'http://localhost:8084'
    ),
    filePath: process.env.VUE_APP_FILE_PATH || (
        isProd ? 'https://api.lesreparables.fr' : 'http://localhost:8082'
    ),
    accountHost: process.env.VUE_APP_ACCOUNT_HOST || (
        isProd ? 'https://mon-compte.lesreparables.fr' : 'http://localhost:3000'
    ),
    accountUrl: process.env.VUE_APP_ACCOUNT_URL || (
        isProd ? 'https://mon-compte.lesreparables.fr' : 'http://localhost:3000'
    )
}
