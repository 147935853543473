export const WEIGHTS = {
    PARCEL: 250,
    DEFAULT: 250,
    MANTEAU: 900,
    'VESTE/BLOUSON': 900,
    DOUDOUNE: 400,
    'AUTRES BAS': 800,
    JEAN: 800,
    'PULL/GILET': 350,
    CHEMISE: 150,
    'POLO/T.SHIRT/BLOUSE': 150,
    'SWEAT/HOODIE': 300,
    'LINGERIE/MAILLOT DE BAIN': 100,
    'SALOPETTE/COMBI/ROBE': 500,
    'SAC A DOS/SAC SOUPLE': 700
}
