/*
|---------------------------------------------------------------------
| flux Vuex Mutations
|---------------------------------------------------------------------
|
| Synchronous operations for flux store
|
*/
export default {
    setEnvironment(state, value) {
        state.environment = {...state.environment, ...value}
    }
}
