import flux from './content/flux'

/*
|---------------------------------------------------------------------
| Flux Vuex State
|---------------------------------------------------------------------
|
| Kanban board initial cards state from content demo
|
*/
export default {
  flux
}
