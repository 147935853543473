<template>
  <div style="padding:1rem">
    <v-row>
      <v-col cols="12">
        <div class="spacer"></div>
        <h2 class="mt10 pt10">AUTRE DEMANDE</h2>
      </v-col>
      <v-col cols="12" sm="12">

        <div v-if="notsubmited">
          <p>
            il s’agit d’une demande spécifique, pour cela, complétez le formulaire :
          </p>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="nom"
                  :rules="nomRules"
                  label="Nom"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="prenom"
                  :rules="prenomRules"
                  label="Prénom"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="telephone"
                  :rules="telephoneRules"
                  label="Téléphone"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="description"
                  :rules="nomRules"
                  label="Description de la réparation à effectuer"
                  name="input-7-1"
                ></v-textarea>
                <br/>
                <br/>
                <br/>
              </v-col>
              <v-col cols="12">
                <div v-if="error" style="color:#FF0000">
                  La photo est obligatoire.
                </div>
                <InputFile :value="photo" @image="getImg"/>
              </v-col>
              <v-col cols="12">
                <div class="btn btn-1 btn-full" @click="validate">ENVOYER MA DEMANDE</div>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div v-else>
          <p> Votre demande personnalisée nous a bien été transmise. <br/>
            Nous reviendrons vers vous dans les plus bref délais<br/><br/>
            Nous vous remercions pour votre confiance<br/><br/>
            L'équipe Les Réparables
          </p>
          <v-row>
            <v-col cols="12">
              <br/>
              <div style="border-top:1px solid #000"></div>
              <br/>
            </v-col>
          </v-row>
          <confirm-flow
            @finalize="noarticle"
            @new-repair="$emit('evenement', 'scroll3')"
            @new-article="$emit('evenement', 'scroll1')"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import InputFile from '../interface/ImagePreview.vue'
import axios from 'axios'
import config from '../../../config'
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";

export default {
  data: () => ({
    notsubmited: true,
    error: false,
    customer: {
      nom: null,
      prenom: null,
      email: null,
      telephone: null,
    },
    photo: null,
    description: null,
    e1: 2,
    valid: true,
    nom: '',
    nomRules: [
      v => !!v || 'Ce champs est requis'
    ],
    prenom: '',
    prenomRules: [
      v => !!v || 'Ce champs est requis'
    ],
    telephone: '',
    telephoneRules: [
      v => !!v || 'Ce champs est requis'
    ],
    email: '',
    emailRules: [
      v => !!v || 'Ce champs est requis',
      v => /.+@.+\..+/.test(v) || 'Le format n\'est pas celui d\'un email',
    ],
    select: null,
    items: [
      'Item 1',
      'Item 2',
      'Item 3',
      'Item 4',
    ],
    checkbox: false,
  }),
  computed: {
    ...mapState('flux-app', ['flux'])
  },
  methods: {
    getImg(value) {
      this.photo = value
    },
    validate() {
      if (this.photo === null) {
        this.error = true
      } else if (this.$refs.form.validate()) {
        this.error = false
        this.customer.nom = this.nom
        this.customer.prenom = this.prenom
        this.customer.email = this.email
        this.customer.telephone = this.telephone
        this.notsubmited = false
        this.sendMail()
      }
    },
    sendMail() {
      return new Promise(() => {
        axios({
          url: `${config.apiHost}/calc-customer/demande-perso`,
          method: 'POST',
          responseType: 'json',
          data: {
            famille: this.flux.level1,
            vetement: this.flux.level2,
            customer: this.customer,
            description: this.description,
            photo: this.photo
          }
        }).then((response) => {
          if (response.data.status === 'success') {
            console.log('ok')
          }
        })
      })
    },
    noarticle() {
      this.$emit('showmodal', 'cart')
      this.$emit('evenement', 'scroll1')
    }
  },
  components: {
    ConfirmFlow,
    InputFile
  }
}
</script>
