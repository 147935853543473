export default {
    level1: null,
    level2: null,
    level3: null,
    isDouble: null,
    constat: null,
    cptProduct: 0,
    cptReparation: 0,
    indexEditProduit: null,
    indexEditReparation: null,
    itemsLevel2: [],
    itemsLevel3: [],
    idLevel1: null,
    idLevel2: null,
    verrou: false,
    actionId: 0,
    isCursor: false,
    componentName: null,
    activeTab: null,
    brand: null,
    origin: null,
}
