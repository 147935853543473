export const formatPrice = (price) => {
    if (price === 0) {
        return 'Gratuit'
    }

    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
    }).format(Math.round(parseFloat(price) * 100) / 100)
}

export const roundPrice = (price) => Math.round(price * 10 / 5) * 5 / 10
