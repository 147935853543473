<template>
  <v-row>
    <v-col class="d-flex align-items-center" cols="6" md="8">
      <p>
        Le zip étanche est principalement utilisé pour les sacs à dos, les doudounes ou les vêtements
        techniques. Le ruban et la maille du zip sont recouverts d'une membrane qui permet d'étancher la
        fermeture.
      </p>
    </v-col>
    <v-col class="pa-1" cols="6" md="4">
      <img src="https://www.lesreparables.fr/cv2/clothetype/modal/zip-etanche.jpg"/>
    </v-col>
    <v-col cols="12">
      <div><br/><br/>Zip étanches</div>
    </v-col>
    <v-col class="pa-1" cols="4">
      <img src="https://www.lesreparables.fr/cv2/clothetype/modal/zip-etanche-1.png"/>
    </v-col>
    <v-col class="pa-1" cols="4">
      <img src="https://www.lesreparables.fr/cv2/clothetype/modal/zip-etanche-2.png"/>
    </v-col>
    <v-col class="pa-1" cols="4">
      <img src="https://www.lesreparables.fr/cv2/clothetype/modal/zip-etanche-3.png"/>
    </v-col>
    <v-col cols="12">
      <div>Zip classiques</div>
    </v-col>
    <v-col class="pa-1" cols="4">
      <img src="https://www.lesreparables.fr/cv2/clothetype/modal/zip-non-etanche-1.png"/>
    </v-col>
    <v-col class="pa-1" cols="4">
      <img src="https://www.lesreparables.fr/cv2/clothetype/modal/zip-non-etanche-2.png"/>
    </v-col>
    <v-col class="pa-1" cols="4">
      <img src="https://www.lesreparables.fr/cv2/clothetype/modal/zip-non-etanche-3.png"/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ZipEtancheModalContent"
}
</script>
