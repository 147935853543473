/*
|---------------------------------------------------------------------
| Flux Vuex State
|---------------------------------------------------------------------
|
| Kanban board initial cards state from content demo
|
*/
export default {
    user: null
}
