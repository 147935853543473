<template>
  <div id="container">
    <div
      id="square"
      :style="{
        'background-color': environment.theme.pictoColor,
        top: squarePosition.top,
        bottom: squarePosition.bottom,
        left: squarePosition.left,
        right: squarePosition.right,
      }"
    />
    <img :alt="alt" :src="src"/>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Picto",
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  computed: {
    ...mapState('environment-app', ['environment']),
    squarePosition() {
      if (this.position === 'top') {
        return {
          top: 0,
          left: '32px'
        }
      }

      if (this.position === 'top-left') {
        return {
          top: 0,
          left: 0
        }
      }

      if (this.position === 'top-right') {
        return {
          top: 0,
          right: 0
        }
      }

      if (this.position === 'bottom') {
        return {
          bottom: 0,
          left: '32px'
        }
      }

      if (this.position === 'bottom-left') {
        return {
          top: 0,
          left: 0
        }
      }

      if (this.position === 'bottom-right') {
        return {
          bottom: 0,
          right: 0
        }
      }

      if (this.position === 'left') {
        return {
          top: '32px',
          left: 0
        }
      }

      if (this.position === 'right') {
        return {
          top: '32px',
          right: 0
        }
      }

      return {}
    }
  }
}
</script>

<style scoped>
#container {
  width: 150px;
  height: 150px;
  position: relative;
  margin: auto;
}

#square {
  width: 85px;
  height: 85px;
  position: absolute;
}

img {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
