<template>
  <div>
    <p>
      Nous vous proposons de confier votre colis à Mondial Relay ou à Colissimo pour l'envoi et le retour. Les pertes de
      colis sont exceptionnelles, mais nous avons pris des mesures pour que vous soyez toujours serein.
    </p>
    <p>
      En cas de perte d'un colis par le transporteur, <strong>l'indemnisation totale est de 25€ sans protection</strong>.
    </p>
    <p>Vous pouvez augmenter la protection :</p>
    <ul class="ul_normal">
      <li v-for="insurance of insurances" :key="insurance.id">
        Participation {{ insurance.price }}€ - {{ insurance.label }}
        <v-icon>mdi-package-variant-closed-check</v-icon>
      </li>
    </ul>
    <p>
      Pour le bon acheminement du colis, il est important que le contenant soit solide, adapté et bien fermé. Si vous
      choisissez la protection transport, veuillez prendre en photo votre colis une fois fermé. Cela nous permettra
      de faciliter la recherche. Cette photo est à conserver, elle vous sera demandée ultérieurement.
    </p>
    <p>
      Pour déterminer la valeur du montant et obtenir le remboursement, le colis doit être déclaré perdu par le
      transporteur et vous devez nous fournir la photo du colis prise lors de l’envoi ainsi qu’un justificatif du
      contenu.
    </p>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {formatPrice} from "@/helper/PriceHelper";

export default {
  name: "InsuranceModalContent",
  methods: {formatPrice},
  computed: {
    ...mapState('insurance-app', ['insurances'])
  }
}
</script>

<style scoped>
ul {
  margin-left: -10px;
  margin-right: -10px;
}
</style>
