<template>
  <v-row>
    <v-col class="d-flex align-items-center" cols="6" md="8">
      Il est possible que la maille du zip soit en bon état. Le problème peut venir du curseur que nous pouvons
      remplacer (exemple : la fermeture s’ouvre, le curseur est bloqué…)
    </v-col>
    <v-col cols="6" md="4">
      <img :src="`https://www.lesreparables.fr/cv2/clothetype/modal/${pictures.main}`"/>
    </v-col>
    <v-col class="mt-5" cols="12">
      Ici, il est possible de changer uniquement le curseur car la maille est intacte
    </v-col>
    <v-col cols="4">
      <img :src="`https://www.lesreparables.fr/cv2/clothetype/modal/${pictures.ok1}`"/>
    </v-col>
    <v-col cols="4">
      <img :src="`https://www.lesreparables.fr/cv2/clothetype/modal/${pictures.ok2}`"/>
    </v-col>
    <v-col cols="4">
      <img :src="`https://www.lesreparables.fr/cv2/clothetype/modal/${pictures.ok3}`"/>
    </v-col>
    <v-col cols="12">
      Ici, nous devons remplacer la fermeture car elle est abîmée : spirale arrachée, manchon et/ou boitier
      cassé ou dents manquantes.
    </v-col>
    <v-col cols="5" md="8">
      <v-row>
        <v-col cols="12" md="6">
          <img :src="`https://www.lesreparables.fr/cv2/clothetype/modal/${pictures.ko1}`"/>
        </v-col>
        <v-col cols="12" md="6">
          <img :src="`https://www.lesreparables.fr/cv2/clothetype/modal/${pictures.ko2}`"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="7" md="4">
      <img :src="`https://www.lesreparables.fr/cv2/clothetype/modal/${pictures.ko3}`"/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ZipModalContent",
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    pictures() {
      if (['MANTEAU', 'DOUDOUNE', 'VESTE/BLOUSON', 'ACCESSOIRES DIVERS'].includes(this.$props.type)) {
        return {
          main: 'curseur-zip.png',
          ok1: 'maille-ok.png',
          ok2: 'maille-ok-2.png',
          ok3: 'maille-ok-manteau-3.png',
          ko1: 'maille-nok.png',
          ko2: 'maille-non-ok-manteau-2.png',
          ko3: 'zip.png',
        }
      }

      if (['PULL/GILET'].includes(this.$props.type)) {
        return {
          main: 'curseur-zip.png',
          ok1: 'maille-ok-pull-1.png',
          ok2: 'maille-ok-pull-2.jpg',
          ok3: 'maille-ok-pull-3.jpg',
          ko1: 'maille-non-ok-pull-1.png',
          ko2: 'maille-non-ok-pull-2.jpg',
          ko3: 'zip.png',
        }
      }

      if (['SWEAT/HOODIE'].includes(this.$props.type)) {
        return {
          main: 'curseur-zip.png',
          ok1: 'maille-ok-sweat-1.jpg',
          ok2: 'maille-ok-sweat-2.jpg',
          ok3: 'maille-ok-sweat-3.png',
          ko1: 'maille-non-ok-pull-1.png',
          ko2: 'maille-non-ok-pull-2.jpg',
          ko3: 'zip.png',
        }
      }

      if (['SALOPETTE/COMBI/ROBE'].includes(this.$props.type)) {
        return {
          main: 'curseur-zip.png',
          ok1: 'maille-ok-salopette-1.png',
          ok2: 'maille-ok-salopette-2.jpeg',
          ok3: 'maille-ok-salopette-3.jpeg',
          ko1: 'maille-non-ok-pull-1.png',
          ko2: 'maille-nok.png',
          ko3: 'zip.png',
        }
      }

      if (['SAC A DOS/SAC SOUPLE'].includes(this.$props.type)) {
        return {
          main: 'curseur-zip.png',
          ok1: 'maille-ok-sac-1.png',
          ok2: 'maille-ok-sac-2.png',
          ok3: 'maille-ok-sac-3.png',
          ko1: 'maille-non-ok-sac-1.png',
          ko2: 'maille-nok.png',
          ko3: 'zip.png',
        }
      }

      return {}
    }
  }
}
</script>
