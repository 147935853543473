<template>
  <div class="home">
    <Calculateur msg="Bienvenue sur notre calculateur" @edit="editRepair" @showmodal="showModal"/>
  </div>
</template>

<script>
import Calculateur from '@/components/Calculateur.vue'

export default {
  name: 'Home',
  methods: {
    editRepair(indexes) {
       this.$emit('edit', indexes)
    },
    showModal(value) {
      this.$emit('showmodal', value)
    }
  },
  components: {
    Calculateur
  }
}
</script>
