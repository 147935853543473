/*
|---------------------------------------------------------------------
| flux Vuex Mutations
|---------------------------------------------------------------------
|
| Synchronous operations for flux store
|
*/
export default {
    setInsurances(state, value) {
        state.insurances = value
    }
}
