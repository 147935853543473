<template>
  <div class="container-fluid">
    <div :style="`background-color: ${environment.theme.bannerBackgroundColor}`" class="row py-5">
      <div class="cold-12 col-md-6">
        <h4 class="px-8">Infos</h4>
        <ul>
          <li><a href="https://www.lesreparables.fr/mentions-legales/" target="_blank">Mentions légales</a></li>
          <li><a href="https://www.lesreparables.fr/cgv-cgu/" target="_blank">Conditions générales de ventes</a></li>
          <li>
            <a href="https://www.lesreparables.fr/politique-de-confidentialite/" target="_blank">
              Politique de confidentialité
            </a>
          </li>
          <li><a href="https://www.lesreparables.fr/politique-de-confidentialite/" target="_blank">Cookies</a></li>
          <li><a href="https://www.lesreparables.fr/reparer-ses-vetements/" target="_blank">Comment ça marche ?</a>
          </li>
        </ul>
      </div>
      <div class="cold-12 col-md-6">
        <h4 class="px-8">Contactez-nous</h4>
        <ul>
          <li><a href="https://www.lesreparables.fr/faq/" target="_blank">F.A.Q.</a></li>
          <li><a href="https://www.lesreparables.fr/contact/" target="_blank">Nous écrire</a></li>
          <li><a href="https://www.lesreparables.fr/blog" target="_blank">Blog</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapState('environment-app', ['environment']),
  }
}
</script>

<style scoped>
ul {
  margin-bottom: 0;
}

li {
  margin: 5px 15px;
}
</style>
