import environment from './content/environment'

/*
|---------------------------------------------------------------------
| Flux Vuex State
|---------------------------------------------------------------------
|
| Kanban board initial cards state from content demo
|
*/
export default {
    environment
}
