/*
|---------------------------------------------------------------------
| flux Vuex Mutations
|---------------------------------------------------------------------
|
| Synchronous operations for flux store
|
*/
export default {
    setIdLevel1: (state, value) => {
        state.flux.idLevel1 = value
    },
    setIdLevel2: (state, value) => {
        state.flux.idLevel2 = value
    },
    setLevel1: (state, value) => {
        state.flux.level1 = value
    },
    setLevel2: (state, value) => {
        state.flux.level2 = value
    },
    setItemsLevel2: (state, value) => {
        state.flux.itemsLevel2 = value
    },
    setLevel3: (state, value) => {
        state.flux.level3 = value
    },
    setItemsLevel3: (state, value) => {
        state.flux.itemsLevel3 = value
    },
    setDouble: (state, value) => {
        state.flux.isDouble = value
    },
    setConstat: (state, value) => {
        state.flux.constat = value
    },
    setcptProduct: (state, value) => {
        state.flux.cptProduct = value
    },
    setVerrou: (state, value) => {
        state.flux.verrou = value
    },
    setcptReparation: (state, value) => {
        state.flux.cptReparation = value
    },
    setindexEditProduit: (state, value) => {
        state.flux.indexEditProduit = value
    },
    setindexEditReparation: (state, value) => {
        state.flux.indexEditReparation = value
    },
    setActionId: (state, value) => {
        state.flux.actionId = value
    },
    setIsCursor: (state, value) => {
        state.flux.isCursor = value
    },
    setComponentName: (state, value) => {
        state.flux.componentName = value
    },
    setActiveTab: (state, value) => {
        state.flux.activeTab = value
    },
    setBrand: (state, value) => {
        state.flux.brand = value
    },
}
