import cards from './content/cards'
import products from './content/products'

/*
|---------------------------------------------------------------------
| Board Vuex State
|---------------------------------------------------------------------
|
| Kanban board initial cards state from content demo
|
*/
export default {
    cards,
    products,
    cgvCguAccepted: false,
    shippingInfo: null
}
