<template>
  <v-row>

    <template v-if="step === 1">
      <v-col class="text-center" cols="12" sm="6">
        <div class="btn btn-light text-center btn-full" @click="step++">
          AJOUTER UNE RÉPARATION
        </div>
      </v-col>
      <v-col class="text-center" cols="12" sm="6">
        <div class="btn btn-1 text-center btn-full" @click="$emit('finalize')">
          FINALISER MA COMMANDE
        </div>
      </v-col>
    </template>

    <template v-if="step === 2">
      <v-col class="text-center" cols="12" sm="6">
        <div class="btn btn-light text-center btn-full" @click="$emit('new-repair')">
          SUR LE MÊME ARTICLE
        </div>
      </v-col>
      <v-col class="text-center" cols="12" sm="6">
        <div class="btn btn-1 text-center btn-full" @click="$emit('new-article')">
          SUR UN NOUVEL ARTICLE
        </div>
      </v-col>
    </template>

  </v-row>
</template>

<script>
export default {
  name: "ConfirmFlow",
  data() {
    return {
      step: 1
    }
  }
}
</script>

<style scoped>
.btn {
  margin-bottom: 12px;
}
</style>
